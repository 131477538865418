import get from 'lodash/get';
import { useMemo } from 'react';

import { OptimizelyFlags, useOptimizelyDecision } from './index';

export const useSitewideBannerData = () => {
  const decision = useOptimizelyDecision(OptimizelyFlags.SITEWIDE_BANNER);

  return useMemo(() => {
    if (!decision) return undefined;

    try {
      const pages = get(decision.variables, 'data.pages', []);
      const content = get(decision.variables, 'data.content', []);
      const theme = get(decision.variables, 'data.theme', {});

      return {
        pages: Array.isArray(pages)
          ? (pages
              .map((p: unknown) => (typeof p === 'string' ? p : undefined))
              .filter(Boolean) as string[])
          : [],
        content: Array.isArray(content)
          ? (content
              .map((c: unknown) => {
                const text = get(c, 'text', undefined);
                const url = get(c, 'url', undefined);

                if (typeof text !== 'string') return undefined;

                return { text, url: typeof url === 'string' ? url : undefined };
              })
              .filter(Boolean) as { text: string; url?: string }[])
          : [],
        theme: {
          background: get(theme, 'background', undefined) as string | undefined,
          text: get(theme, 'text', undefined) as string | undefined,
        },
      };
    } catch {
      return undefined;
    }
  }, [decision]);
};
