export enum EPage {
  SEARCH = 'search',
  PROMOS = 'promos',
  CAMPGROUND_SEARCH = 'campground-search',
  VACATION_PACKAGES = 'vacation-packages',
}

export const PAGE_PATHNAME = {
  [EPage.SEARCH]: '/rv-search',
  [EPage.PROMOS]: '/promos',
  [EPage.CAMPGROUND_SEARCH]: '/campground-rental',
  [EPage.VACATION_PACKAGES]: '/vacation-packages',
};

export const PAGE_TITLE_SUFFIX = ' | Outdoorsy';
