import { ETextStyleVariant, Icon, Text } from '@outdoorsyco/bonfire';
import React from 'react';

import Anchor from '@/components/switchback/Anchor/Anchor';
import { useRouter } from '@/hooks/useRouter';
import { useSitewideBannerData } from '@/services/experiments/sitewideBanner';

import { Ticker } from './Ticker';

export const SitewideBanner = () => {
  const router = useRouter();

  const data = useSitewideBannerData();

  if (!data || !data.content.length || !data.pages.includes(router.pathname)) return null;

  return (
    <div style={{ background: data.theme.background }}>
      <Ticker
        items={data.content.map((c, index) => {
          const content = (
            <Text
              component="div"
              variant={ETextStyleVariant.LegalRegular}
              style={{ color: data.theme.text }}>
              {c.text}
            </Text>
          );

          if (c.url) {
            return (
              <Anchor key={index} href={c.url} className="flex items-center gap-2 group">
                {content}

                <Icon
                  name="General.Arrow.Right"
                  style={{ color: data.theme.text }}
                  className="w-4 h-4 lg:opacity-0 lg:group-hover:opacity-100 transition-opacity"
                />
              </Anchor>
            );
          }

          return <div key={index}>{content}</div>;
        })}
      />
    </div>
  );
};
