import { ThunkAction } from 'redux-thunk';

import { getItemsPerPage } from '@/constants/pagination';
import { ESearchFilters } from '@/constants/searchFilters';
import { TRootState } from '@/redux/rootReducer';
import searchApiRequest, { TQueryParams } from '@/services/searchApiRequest';
import { EDeliveryOption } from '@/services/types/core/delivery.types';
import { ICampgroundData } from '@/services/types/search/campgrounds/id';
import { IData, ISearchRentals } from '@/services/types/search/rentals/id';
import { filterSafeQuery } from '@/utility/filterSafeQuery';
import { getStateName, provinceByCountry, TCountry } from '@/utility/location';
import { logger } from '@/utility/logger';
import { IAction } from '@/utility/redux/action';

import { getCurrency } from '../selectors/currency';

// TODO: Add async actions (REQUEST/FAIL/SUCCESS)
const LISTINGS_LOADING = 'bundled-listings/LISTINGS_LOADING';
const SET_LISTINGS = 'bundled-listings/SET_LISTINGS';
const SET_IS_LOADING = 'bundled-listings/SET_IS_LOADING';

interface ISetIsLoadingAction {
  type: typeof SET_IS_LOADING;
  payload: boolean;
}
interface IListingsLoadingAction extends IAction {
  type: typeof LISTINGS_LOADING;
}

interface ISetListingsAction extends IAction {
  type: typeof SET_LISTINGS;
  payload: IData[];
}

type TAction = IListingsLoadingAction | ISetListingsAction | ISetIsLoadingAction;

const setBundledListings = (payload: IData[]): ISetListingsAction => ({
  type: SET_LISTINGS,
  payload,
});

const doRentalListsMatch = (l1: IData[], l2: IData[]): boolean => {
  return l1.length === l2.length && l1.every((el, index) => el.id === l2[index]?.id);
};

const mapCampgroundDataToQueryParams = (campgroundData: ICampgroundData): TQueryParams => {
  const { lng, lat, street, city, state, zip, country } = campgroundData.location;

  const formattedCountry = (country?.toUpperCase() as TCountry) || 'US';

  const stateName = getStateName(state, formattedCountry) ?? state;
  const countryName = provinceByCountry[formattedCountry].name;
  const address = `${street}, ${city}, ${stateName} ${zip}, ${countryName}`;
  return {
    [ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID]: campgroundData.id,
    [ESearchFilters.DELIVERY_ADDRESS]: address,
    [ESearchFilters.DELIVERY]: true,
    [ESearchFilters.CHECK_DRIVABLE_DISTANCE]: true,
    [ESearchFilters.DELIVERY_CENTER]: JSON.stringify([lng, lat]),
    [ESearchFilters.DELIVERY_DETAILS]: encodeURIComponent(
      JSON.stringify({
        country: countryName,
        state: stateName,
        city,
        street,
        zip,
      }),
    ),
    [ESearchFilters.DELIVERY_STATIONARY]: EDeliveryOption.STATIONARY,
    [ESearchFilters.PAGE_LIMIT]: getItemsPerPage().toString(),
    [ESearchFilters.SUGGESTED]: true,
    [ESearchFilters.OMIT_AGGREGATION]: true,
    [ESearchFilters.ADDRESS]: address,
    [ESearchFilters.PAGE_OFFSET]: String(0),
  };
};

export const getBundledListings =
  (
    params?: TQueryParams,
  ): ThunkAction<Promise<IData[]>, TRootState, void, ISetListingsAction | IListingsLoadingAction> =>
  async (dispatch, getState) => {
    dispatch<IListingsLoadingAction>({
      type: LISTINGS_LOADING,
    });

    const currency = getCurrency(getState());
    const campgroundData = getState().campgroundListing.data;
    const campgroundQueryParams = campgroundData?.id
      ? mapCampgroundDataToQueryParams(campgroundData)
      : {};
    const safeQueryParams = filterSafeQuery({ ...params, ...campgroundQueryParams });
    const endpoint = 'rentals';

    return new Promise((resolve, reject) =>
      searchApiRequest<ISearchRentals>(endpoint, {
        currency,
        rejectOnError: true,
        queryParams: {
          ...safeQueryParams,
        },
      })
        .then(response => {
          if (!response?.data) {
            throw new Error('404');
          }

          let data = response.data;

          const nearbyRVs = response.suggestions?.nearby_rvs?.data;

          if (nearbyRVs?.length) {
            // TODO Remove me after updating BE --
            // BE may dupe results in data for legacy reasons. If so, set it to empty.
            if (doRentalListsMatch(nearbyRVs, data)) {
              data = [];
            }
          }

          dispatch(setBundledListings(data.slice(0, 6))); //Only show the first 6 listings for now

          return resolve(data);
        })
        .catch(error => {
          logger.captureExceptionWithDatadog(error, { url: error.request?._currentUrl });

          return reject(error);
        }),
    );
  };

interface IState {
  data: IData[];
  isLoading: boolean;
}

export const initialState: IState = {
  data: [],
  isLoading: true,
};

// TODO: Add async actions (REQUEST/FAIL/SUCCESS)
export default function reducer(state = initialState, action: TAction): IState {
  switch (action.type) {
    case LISTINGS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_LISTINGS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return { ...state };
  }
}
